var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sign-in"},[_c('a-row',{staticStyle:{"margin-top":"80px"},attrs:{"type":"flex","gutter":[24,24],"justify":"space-around","align":"middle"}},[_c('a-col',{staticClass:"col-form pt-20 mt-20",attrs:{"span":24,"sm":{span: 16, offset: 0},"md":{span: 11, offset: 0},"lg":{span: 9, offset: 0},"xl":{span: 7, offset: 0}}},[_c('a-card',{staticClass:"bg-white px-0 mx-0 pt-10",attrs:{"bordered":false}},[_c('a-row',{attrs:{"gutter":[24,24]}},[_c('a-col',{attrs:{"span":24,"md":24,"lg":24}},[_c('a-row',{staticClass:"text-center mb-0 pb-0 mt-2"},[_c('a-col',{staticClass:"mb-0 pb-0",attrs:{"lg":24}},[_c('img',{staticClass:"mb-0 pb-0",staticStyle:{"border-radius":"20px"},attrs:{"src":require("@/assets/images/icon.png"),"alt":"","width":"190px","height":"150px"}})])],1),_c('h3',{staticClass:"mb-0 mt-4 pt-0 text-center"},[_vm._v("Welcome Back!")]),_c('p',{staticClass:"text-muted text-center mt-0 pt-0"},[_vm._v("Login to continue")]),(_vm.error.show)?_c('a-alert',{staticClass:"mx-25",attrs:{"message":_vm.error.message,"type":"error","closable":"","after-close":_vm.handleClose,"show-icon":""}}):_vm._e(),_c('a-form',{staticClass:"login-form mx-25 mt-20",attrs:{"id":"components-form-demo-normal-login","form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"mb-2",attrs:{"label":"Email Address/ Phone Number","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                        'username',
                                        { rules: [{ required: true, message: 'Please enter your username!' }] },
                                        ]),expression:"[\n                                        'username',\n                                        { rules: [{ required: true, message: 'Please enter your username!' }] },\n                                        ]"}],attrs:{"placeholder":"Eg: johndoe@gmail.com or 0621314682"}})],1),_c('a-form-item',{staticClass:"mb-4",attrs:{"label":"Password","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'password',
									{ rules: [{ required: true, message: 'Please enter your password!' }] },
									]),expression:"[\n\t\t\t\t\t\t\t\t\t'password',\n\t\t\t\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please enter your password!' }] },\n\t\t\t\t\t\t\t\t\t]"}],attrs:{"type":"password","placeholder":"Password"}})],1),_c('a-form-item',{staticClass:"mt-1"},[_c('a-button',{staticClass:"login-form-button mt-10 text-white",attrs:{"loading":_vm.isLoading,"type":"primary","block":"","html-type":"submit"}},[_vm._v(" SIGN IN ")])],1),_c('a-row',{staticClass:"pb-3",attrs:{"gutters":24}},[_c('a-col',{staticClass:"text-center",attrs:{"span":24}},[_c('a',{staticClass:"text-primary",staticStyle:{"text-decoration":"none !important"},attrs:{"href":`/forgot-password`}},[_c('span',[_vm._v("Forgot password?")])])])],1),_c('a-row',{staticClass:"pt-2 pb-2",attrs:{"gutters":24}},[_c('a-col',{staticClass:"text-center",attrs:{"span":24}},[_vm._v(" Don't have an account? "),_c('a',{staticClass:"text-primary",staticStyle:{"text-decoration":"none !important"},attrs:{"href":`/sign-up`}},[_c('strong',[_vm._v("Sign up")])])])],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }